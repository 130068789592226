<template>
  <div>
    <PatientButton @getPatientInfo="getPatientInfo" />
    <div v-if="list.length > 0">
      <van-collapse
        v-for="(item, index) in list"
        :key="index"
        v-model="activeNames"
        class="feeList"
        accordion
      >
        <van-collapse-item :title="'日期：' + item.date" :name="index">
          <div class="grid-container" style="border-bottom: 1px solid">
            <div class="grid-item">名称</div>
            <div class="grid-item">单价(元)</div>
            <div class="grid-item">数量</div>
            <div class="grid-item">总价(元)</div>
          </div>
          <div
            v-for="(itemInfo, indexInfo) in item.feeInfo"
            :key="indexInfo"
            class="grid-container"
          >
            <div class="grid-item">{{ itemInfo.itemName }}</div>
            <div class="grid-item">{{ itemInfo.itemPrice }}</div>
            <div class="grid-item">{{ itemInfo.itemQuantity }}</div>
            <div class="grid-item">{{ itemInfo.totalMoney }}</div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <van-empty v-else description="暂无住院信息，请重新查询" />
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'

  import PatientButton from '@/components/PatientButton'
  import { Dialog, Toast } from 'vant'
  import { getHospitalFeeList } from '@/api/his/his'
  export default {
    name: 'Pay',
    components: { PatientButton },
    data() {
      return {
        activeNames: ['1'],
        charGeaMount: '0',
        list: [],
        month: '',
        search: {
          PatientId: '',
          BeginTime: '',
          EndTime: '',
        },
        tabOptions: [
          {
            value: 1,
            label: '门诊',
          },
          {
            value: 2,
            label: '住院',
          },
        ],
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      if (userInfo != null) {
        this.search.PatientId = userInfo.patinetId
        await this.pageInit()
        await this.fetchData()
      } else {
        Dialog.alert({
          message: '暂未绑定就诊人，点击确定前往。',
          closeOnPopstate: false,
        }).then(() => {
          this.$router.push({
            path: '/message',
            query: { sign: 'gzrgl' },
          })
        })
      }
    },
    beforeDestroy() {
      Dialog.close()
    },
    methods: {
      //初始化
      async pageInit() {
        let data = JSON.parse(sessionStorage.getItem('day'))
        this.day = data.day
        this.month = data.zyMonth
        this.search.BeginTime = moment(new Date())
          .add(-this.month, 'month')
          .format('yyyy-MM-DD')
        this.search.EndTime = moment(new Date()).format('yyyy-MM-DD')
      },
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getHospitalFeeList(this.search)
        this.charGeaMount = data.chargeamount
        this.list = data.hospitalFeeData
        Toast.clear()
      },

      changeTab(name) {
        this.search.PatientType = name
        this.fetchData()
      },

      async getPatientInfo() {
        let userInfo = JSON.parse(store.getters['user/userInfo'])
        if (userInfo != null) {
          this.search.PatientId = userInfo.patinetId
          await this.fetchData()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .feeList {
    width: 95%;
    margin: 0.4rem auto auto;
    // background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .van-collapse-item {
      margin-bottom: 0.3rem;
      overflow: hidden;
      border-radius: 10px;
      ::v-deep {
        .van-collapse-item__title {
          font-weight: bolder;
          padding: 0.3rem;
          background: linear-gradient(
            77deg,
            #e0ebfd 2%,
            #ffffff 96%,
            #ffffff 96%
          );
          color: #1691fe;
        }
      }
      .grid-container {
        display: grid;
        grid-template-columns: 45% 20% 15% 20%;
      }

      .grid-item {
        padding: 5px;
        text-align: center;
        color: #000;
      }
      .grid-item:nth-child(4n + 1) {
        text-align: left;
      }
    }
  }
</style>
